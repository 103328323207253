import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaValidTokenGuard } from '@priva/auth/current-user';
import { PrivaErrorRouteUrls } from '@priva/error-pages';

import { AppMainComponent } from './app-main.component';

const routes: Routes = [
    {
        path: PrivaErrorRouteUrls.Base,
        loadChildren: async () => (await import('./common/routing/error-pages.module')).AppErrorPagesModule,
    },
    {
        path: 'signout',
        loadChildren: async () => (await import('./common/routing/sign-out.module')).AppSignOutModule,
    },
    {
        path: 'solutions',
        component: AppMainComponent,
        canActivate: [PrivaValidTokenGuard],
        children: [
            {
                path: '',
                loadChildren: async () => (await import('./solutions/solutions.module')).SolutionsModule,
            },
        ],
    },
    { path: '**', redirectTo: 'solutions', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
