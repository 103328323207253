import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ENV_URIS_TOKEN, environmentUrisFactory } from 'app/configuration/env-provider/env-provider';

import { ProductApiEffects } from './product-api.effects';
import { ProductEffects } from './product.effects';
import { productReducer } from './product.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('product', productReducer),
        EffectsModule.forFeature([ProductApiEffects, ProductEffects]),
    ],
    providers: [{ provide: ENV_URIS_TOKEN, useFactory: environmentUrisFactory }],
})
export class ProductStateModule {}
