import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { Firmware } from '@priva/next-model';

import { getFirmware, getLatestFirmwares } from 'app/product/state/product.actions';
import { AppState } from 'app/state/app.state';

import { FirmwareLocalStorageService } from '../../local-storage/firmware-local-storage.service';
import { X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncFirmwareInterceptor implements HttpInterceptor {
    constructor(
        private localStorageService: FirmwareLocalStorageService,
        private store: Store<{ app: AppState }>,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === getLatestFirmwares.type) {
            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.app.online
                        ? this.handleOnlineFirmwares(next, request)
                        : this.handleOfflineFirmwares(),
                ),
            );
        }
        if (request.headers.get(X_HEADER) === getFirmware.type) {
            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.app.online ? next.handle(request) : this.handleOfflineFirmware(),
                ),
            );
        }
        return next.handle(request);
    }

    private handleOnlineFirmwares(next: HttpHandler, request: HttpRequest<unknown>) {
        return next.handle(request).pipe(
            tap((response) => {
                if ((response as HttpResponse<Firmware[]>).ok) {
                    this.localStorageService.put((response as HttpResponse<Firmware[]>).body);
                }
            }),
        );
    }

    private handleOfflineFirmwares(): Observable<HttpResponse<any>> {
        return from(this.localStorageService.getFirmwares()).pipe(
            map((firmwares: Firmware[]) => new HttpResponse({ body: firmwares })),
        );
    }

    private handleOfflineFirmware(): Observable<HttpResponse<any>> {
        return from(this.localStorageService.getLatestFirmware()).pipe(
            map((firmware: Firmware) => new HttpResponse({ body: firmware })),
        );
    }
}
