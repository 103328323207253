import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ErrorHandlingService } from 'app/common/error-handling';
import { undoRedoMetaReducer } from 'app/common/undo/state/undo-redo.meta.reducer';
import { ENV_URIS_TOKEN, environmentUrisFactory } from 'app/configuration/env-provider/env-provider';
import { ProductStateModule } from 'app/product/state/product-state.module';
import { DatasheetStateModule } from 'app/template/datasheet/state/datasheet-state.module';

import { environment } from '../../environments/environment';
import { AppEffects } from './app.effects';
import { appReducer } from './app.reducer';

/* istanbul ignore next lvb: depends on environment */
export const devImports = !environment.production
    ? [
          StoreDevtoolsModule.instrument({
              name: environment.configuration.name,
              maxAge: 500,
              logOnly: environment.production, // Restrict extension to log-only mode})
          }),
      ]
    : [];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer<any>[] =
    !environment.production && environment.debugState ? [debug, undoRedoMetaReducer] : [undoRedoMetaReducer];

/**
 * Preparation for State Management
 */
export const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false, // WvK: does not seem to work with Ivy
        strictActionImmutability: false, // WvK: does not seem to work with Ivy
        strictStateSerializability: true, // changed with Commissioning merge
        strictActionSerializability: false,
    },
};

@NgModule({
    imports: [
        DatasheetStateModule, // Get all datasheets after App initialization
        ProductStateModule, // Download latest firmware if needed after App initialization
        StoreModule.forRoot({ app: appReducer, router: routerReducer }, { metaReducers, ...STORE_CONFIG }),
        EffectsModule.forRoot([AppEffects]),

        StoreRouterConnectingModule.forRoot(),
        ...devImports,
    ],
    providers: [ErrorHandlingService, { provide: ENV_URIS_TOKEN, useFactory: environmentUrisFactory }],
})
export class AppStateModule {}
