import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as undoReducer from './state/undo-redo.reducer';
import { UndoRedoDirective } from './undo-redo.directive';
import { UndoRedoService } from './undo-redo.service';

@NgModule({
    declarations: [UndoRedoDirective],
    exports: [UndoRedoDirective],
    imports: [
        CommonModule,
        StoreModule.forFeature('undoRedo', undoReducer.reducer),
        EffectsModule.forFeature([UndoRedoService]),
    ],
})
export class UndoRedoModule {}
