import { DragDropModule } from '@angular/cdk/drag-drop';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

import { AppShellUtilitiesModule } from '@priva/appshell';
import { PrivaAuthCurrentUserModule } from '@priva/auth/current-user';
import { PrivaAuthHttpModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { AppScreenSizeDetectorModule } from '@priva/components-candidates/app-screen-size-detector';
import { FLOORPLAN_BASEURL_TOKEN } from '@priva/components-candidates/floorplan';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { NextThrobberModule } from '@next/design-system/throbber';
import { httpInterceptorProviders } from 'app/common/http';
import { ENV_BASE_TOKEN, environmentBaseFactory } from 'app/configuration';

import { environment } from '../environments/environment';
import { AppMainModule } from './app-main.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConsoleModule } from './common/console/console.module';
import { DuplicateModule } from './common/duplicate/duplicate.module';
import { StatusErrorPageModule } from './common/error-handling/status-error-page/status-error-page.module';
import { FeatureGuard } from './common/http/feature.guard';
import { OfflineSyncModule } from './common/offline-sync/offline-sync.module';
import { NavigationModule } from './common/routing/navigation.module';
import { UndoRedoModule } from './common/undo/undo-redo.module';
import { AppConfigurationModule, AppConfigurationService } from './configuration';
import { AppStateModule } from './state/app-state.module';

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: Object.values(environment.configuration.uris),
        sendAccessToken: true,
    },
};

function getCustomAuthOptions(configService: AppConfigurationService): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.authConfig = configService.configuration.authentication
        ? configService.configuration.authentication.authConfig
        : undefined;
    return options;
}

function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    const options = new PrivaLocalizationOptions();
    options.path = 'assets/translations';
    options.version = environment.version;
    options.availableLanguagesResource = 'assets/translations/locales.json';
    options.defaultLanguage = 'en-US';
    return options;
}

function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        HttpClientModule,
        AppConfigurationModule.forRoot(),
        PrivaAuthHttpModule.forRoot({
            provide: PrivaAuthHttpOptions,
            useFactory: getCustomAuthOptions,
            deps: [AppConfigurationService],
        }),
        PrivaAuthCurrentUserModule.forRoot(),

        NavigationModule.forRoot(),
        PrivaWindowEventsModule.forRoot(),
        PrivaScrollbarModule.forRoot(),
        PrivaToggleModule.forRoot(),
        PrivaNotificationsModule.forRoot(),
        PrivaScrollToModule.forRoot(),
        PrivaLocalizationModule.forRoot({
            provide: PrivaLocalizationOptions,
            useFactory: getCustomLocalizationOptions,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: getVersionBasedTranslationLoader,
                deps: [HttpClient, PrivaLocalizationOptions],
            },
        }),

        AppRoutingModule,
        AppShellUtilitiesModule,
        AppMainModule,
        AppScreenSizeDetectorModule,
        AppStateModule,

        NextThrobberModule,
        UndoRedoModule,
        DuplicateModule,
        DragDropModule,
        StatusErrorPageModule,

        OfflineSyncModule,
        // Needed to prevent a token injection error when used in a lazy module
        // https://stackoverflow.com/questions/64804090/runtime-error-ng-select-selection-model
        NgSelectModule,
        ConsoleModule,
    ],
    providers: [
        AppConfigurationService,
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useValue: localStorage },

        { provide: APP_BASE_HREF, useValue: '/' },
        FeatureGuard,
        httpInterceptorProviders,
        {
            provide: FLOORPLAN_BASEURL_TOKEN,
            useValue: environment.configuration.uris.floorplanApiUrl,
        },
        {
            provide: ENV_BASE_TOKEN,
            useFactory: environmentBaseFactory,
        },
        PrivaErrorPageHandlerService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
