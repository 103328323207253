import { Action, createReducer, on } from '@ngrx/store';

import { FirmwareActions } from '.';
import { initialProductState, ProductState } from './product.state';

/* istanbul ignore next, standard reducer pattern */
const reducer = createReducer<ProductState>(
    initialProductState,
    on(FirmwareActions.getLatestFirmwareSuccess, (state, action): ProductState => {
        return {
            ...state,
            latestFirmware: { ...action.firmware },
        };
    }),
);

export function productReducer(state: ProductState | undefined, action: Action) {
    return reducer(state, action);
}
