import { createAction, props } from '@ngrx/store';

import { Firmware, FirmwareFilter } from '@priva/next-model';

export const getFirmware = createAction(
    '[Product] Get a firmware',
    props<{ product: string; version: string }>(),
);
export const getFirmwareSuccess = createAction(
    '[Product] Get firmware (success)',
    props<{ firmwares: Firmware[] }>(),
);

export const getLatestFirmwares = createAction(
    '[Product] Get latest firmwares if needed',
    props<{ product: string; filter?: FirmwareFilter }>(),
);
export const getLatestFirmwareSuccess = createAction(
    '[Product] Get latest firmware (success)',
    props<{ firmware: Firmware }>(),
);

export const downloadFirmware = createAction(
    '[Product] Download a firmware',
    props<{ firmware: Firmware }>(),
);
export const downloadFirmwareSuccess = createAction(
    '[Product] Download a firmware (success)',
    props<{ firmware: Firmware }>(),
);
export const downloadFirmwareFailed = createAction(
    '[Product] Download a firmware (failed)',
    props<{ error: string }>(),
);
