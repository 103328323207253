import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrivaLocalizationModule } from '@priva/localization';

import { ThrobberDirective } from './throbber.directive';

@NgModule({
    declarations: [ThrobberDirective],
    exports: [ThrobberDirective],
    imports: [CommonModule, PrivaLocalizationModule],
})
export class NextThrobberModule {}
