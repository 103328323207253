import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import { redo, undo } from './state/undo-redo.actions';
import { UndoRedoState } from './state/undo-redo.state';

@Directive({
    selector: '[app-undo-redo]',
})
export class UndoRedoDirective {
    constructor(private store$: Store<{ undoRedo: UndoRedoState }>) {}

    @HostListener('window:keydown', ['$event'])
    public listen(keyEvent: KeyboardEvent) {
        if (keyEvent.code === 'KeyZ' && (keyEvent.ctrlKey || keyEvent.metaKey) && keyEvent.shiftKey) {
            this.store$.dispatch(redo());
        }

        if (keyEvent.code === 'KeyZ' && (keyEvent.ctrlKey || keyEvent.metaKey) && !keyEvent.shiftKey) {
            this.store$.dispatch(undo());
        }
    }
}
