import { Component, Input, OnInit } from '@angular/core';

/** @dynamic */
@Component({
    selector: 'priva-screen-size-detector',
    templateUrl: './dialog-screen-size-exceed.component.html',
})
export class DialogUndercutScreenLimitComponent implements OnInit {
    @Input()
    public width: number | undefined;
    public translateParams: object | undefined;

    public ngOnInit(): void {
        this.translateParams = { width: this.width };
    }
}
