import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import Dexie from 'dexie';

import { OfflineSyncControllerPropertiesInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-controller-properties.interceptor';
import { OfflineSyncControllerProtocolSettingsInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-controller-protocol-settings.interceptor';
import { OfflineSyncDaliDevicesInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-dali-devices.interceptor';
import { OfflineSyncElementBindingStatusInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-element-binding-status.interceptor';
import { OfflineSyncHardwareRegistrationsInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-hardware-registration.interceptor';
import { OfflineSyncSearchDeviceDatasheetsInterceptor } from 'app/common/offline-sync/interceptor/offline-sync-search-device-datasheets.interceptor';
import { ENV_URIS_TOKEN, environmentUrisFactory } from 'app/configuration/env-provider/env-provider';

import { DEXIE_CONFIG, DexieConfig } from '../local-storage/provider/dexie.config';
import { OfflineSyncConfigurationInterceptor } from './interceptor/offline-sync-configuration.interceptor';
import { OfflineSyncElementInterceptor } from './interceptor/offline-sync-element.interceptor';
import { OfflineSyncFirmwareInterceptor } from './interceptor/offline-sync-firmware.interceptor';
import { OfflineSyncFloorplanInterceptor } from './interceptor/offline-sync-floorplan.interceptor';
import { OfflineSyncIoMappingInterceptor } from './interceptor/offline-sync-io-mapping.interceptor';
import { OfflineSyncModelInterceptor } from './interceptor/offline-sync-model.interceptor';
import { OfflineSyncSolutionInterceptor } from './interceptor/offline-sync-solution.interceptor';
import { OfflineSyncSolutionsInterceptor } from './interceptor/offline-sync-solutions.interceptor';
import { OfflineSyncTopologyInterceptor } from './interceptor/offline-sync-topology.interceptor';

@NgModule({
    declarations: [],
    providers: [
        { provide: DEXIE_CONFIG, useValue: DexieConfig },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncSolutionsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncSolutionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncTopologyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncFloorplanInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncElementInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncModelInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncFirmwareInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncConfigurationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncIoMappingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncHardwareRegistrationsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncControllerPropertiesInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncElementBindingStatusInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncDaliDevicesInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineSyncSearchDeviceDatasheetsInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineSyncControllerProtocolSettingsInterceptor,
            multi: true,
        },
        { provide: Dexie, useValue: new Dexie(DexieConfig.databaseName) },
        { provide: ENV_URIS_TOKEN, useFactory: environmentUrisFactory },
    ],
})
export class OfflineSyncModule {}
