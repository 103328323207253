import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { PrimaryNavigationService } from '@priva/appshell';
import { PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class NeXtPrimaryNavigationService implements PrimaryNavigationService {
    private navigation$: Subject<PrivaNavigationItem[]> = new ReplaySubject();

    public get navigation(): Subject<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
