<priva-dialog
    class="whats-new-dialog"
    type="dynamic"
    alignment="center"
    [title]="'APP.WHATS_NEW.HEADER' | translate"
    [buttons]="buttons"
    (buttonClick)="buttonClick()"
    (close)="close()"
    (backdropClick)="onBackdropClick($event)"
>
    <p>Soon you can find all the announcements on new functionality here!</p>
</priva-dialog>
