import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrivaButtonModule } from '@priva/components/button';
import { PrivaExpanderModule } from '@priva/components/expander';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaWellModule } from '@priva/components/well';

import { ConsoleComponent } from './console.component';

@NgModule({
    declarations: [ConsoleComponent],
    imports: [CommonModule, PrivaWellModule, PrivaHeaderModule, PrivaExpanderModule, PrivaButtonModule],
    exports: [ConsoleComponent],
})
export class ConsoleModule {}
