import { Firmware } from '@priva/next-model';

export interface ProductStateContainer {
    product: ProductState;
}

export interface ProductState {
    latestFirmware: Firmware;
}

export const initialProductState: ProductState = {
    latestFirmware: undefined,
};
