import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Feature, FeatureCollection } from 'geojson';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { toFeatureCollection } from '@priva/components-candidates/floorplan';

import { ENV_URIS_TOKEN } from 'app/configuration/env-provider/env-provider';
import { EnvUris } from 'app/configuration/env-provider/env-provider.model';
import { SolutionState } from 'app/solution/state/solution.state';

import { FloorplanLocalStorageService } from '../../local-storage/floorplan-local-storage.service';
import { extractSolutionIdFromFloorplanApi, extractZoneIdFromFloorplanApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncFloorplanInterceptor implements HttpInterceptor {
    constructor(
        private localStorageService: FloorplanLocalStorageService,
        private floorplanLocalStorageService: FloorplanLocalStorageService,
        private store: Store<{ solution: SolutionState }>,
        @Inject(ENV_URIS_TOKEN) private readonly environmentUris: EnvUris,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            !request.url.startsWith(this.environmentUris.floorplanApiUrl) ||
            request.url.indexOf('zones') === -1
        ) {
            return next.handle(request);
        }
        return this.store.pipe(
            first(),
            switchMap((state) =>
                state.solution?.active?.offline ? this.handleOffline(request) : next.handle(request),
            ),
        );
    }

    private handleOffline(request): Observable<HttpResponse<any>> {
        return this.getFromDb(request).pipe(
            map((f: FeatureCollection | Feature) => new HttpResponse({ body: f })),
        );
    }

    private getFromDb(request): Observable<Feature | FeatureCollection> {
        const solutionId = extractSolutionIdFromFloorplanApi(request.url);
        const siteId = extractZoneIdFromFloorplanApi(request.url);
        if (request.url.endsWith('/zones')) {
            return from(this.localStorageService.getFloorplanZoneZones(solutionId, siteId)).pipe(
                map(toFeatureCollection),
            );
        } else if (request.url.endsWith('/elements')) {
            return from(this.localStorageService.getFloorplanElements(solutionId)).pipe(
                map(toFeatureCollection),
            );
        } else {
            return from(this.localStorageService.getFloorplanZone(solutionId, siteId));
        }
    }
}
