import { Action, ActionReducer } from '@ngrx/store';

import { UndoType } from '../undo-redo.model';
import { UndoRedoService } from '../undo-redo.service';

export function undoRedoMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        const hasUndo = !!UndoRedoService.actions.filter((a) => action && a === action.type)[0];
        const isUndo = action['isUndo'];

        if (hasUndo && !isUndo) {
            let actions: Action | Action[] = UndoRedoService.getUndo(state, action);
            if (!(actions instanceof Array)) {
                actions = [actions as Action];
            }

            const undoAction: UndoType = {
                redoAction: UndoRedoService.getRedo(state, action),
                undoActions: actions,
            };

            return reducer(
                {
                    ...state,
                    undoRedo: {
                        undo: [
                            ...state.undoRedo.undo.slice(Math.max(state.undoRedo.undo.length - 4, 0)),
                            undoAction,
                        ],
                        redo: [],
                    },
                },
                action,
            );
        }

        return reducer(state, action);
    };
}
