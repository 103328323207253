<priva-dialog
    classes="screen-size-exceed-dialog"
    type="fullscreen"
    alignment="center"
    [buttons]="[]"
    [headerButtons]="[]"
    [title]="'GLOBAL.APPLICATION.SCREEN.MIN_WIDTH.TITLE' | translate"
>
    <div class="text-center plain-text">
        <img src="/assets/icons/next/min-width.svg" alt="" width="200" height="200" />
        <p>{{ 'GLOBAL.APPLICATION.SCREEN.MIN_WIDTH.BODY' | translate: translateParams }}</p>
    </div>
</priva-dialog>
