import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ControllerConfigurationInfo } from '@priva/next-model';

import { CxssConfigurationActions } from 'app/solution/controllers/state/configuration';
import { SolutionState } from 'app/solution/state';

import { ConfigurationLocalStorageService } from '../../local-storage/configuration-local-storage.service';
import { extractSolutionIdFromNextApi, X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncConfigurationInterceptor implements HttpInterceptor {
    constructor(
        private configurationLocalStorageService: ConfigurationLocalStorageService,
        private store: Store<{ solution: SolutionState }>,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            request.headers.get(X_HEADER) ===
            CxssConfigurationActions.getAllLatestGeneratedConfigurations.type
        ) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.solution?.active?.offline ? this.handleOffline(solutionId) : next.handle(request),
                ),
            );
        }

        return next.handle(request);
    }

    private handleOffline(solutionId: string): Observable<HttpResponse<object>> {
        return from(this.configurationLocalStorageService.getLatest(solutionId)).pipe(
            map(
                (configurations: ControllerConfigurationInfo[]) => new HttpResponse({ body: configurations }),
            ),
        );
    }
}
