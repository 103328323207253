import { UndoType } from '../undo-redo.model';

export interface UndoRedoState {
    undo: UndoType[];
    redo: UndoType[];
}

export const initialUndoRedoState: UndoRedoState = {
    undo: [],
    redo: [],
};
