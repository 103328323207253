import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SolutionsActions } from 'app/solutions/state';

import { FirmwareActions } from '.';

@Injectable({
    providedIn: 'root',
})
export class ProductEffects {
    public getLatestFirmware$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SolutionsActions.getSolutions),
            map(() =>
                FirmwareActions.getLatestFirmwares({ product: 'ZoneController', filter: { latest: true } }),
            ),
        ),
    );

    constructor(private actions$: Actions) {}
}
