import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Firmware, FirmwareResources } from '@priva/next-model';

@Injectable({
    providedIn: 'root',
})
export class ProductFilesService {
    constructor(private httpClient: HttpClient) {}

    public downloadFirmware(firmware: Firmware): Observable<FirmwareResources> {
        const urls = [firmware.imageUrl, firmware.imageSignatureUrl];
        if (firmware.releaseNotesUrl) {
            urls.push(firmware.releaseNotesUrl);
        }

        return forkJoin(
            urls.map((url) =>
                this.httpClient
                    .get(url, { responseType: 'blob' })
                    .pipe(map((contents) => ({ contents, path: url }))),
            ),
        ).pipe(map(([image, signature, releaseNote]) => ({ image, signature, releaseNote })));
    }
}
