import { Action, createReducer, on } from '@ngrx/store';

import { redoDone, undoDone } from './undo-redo.actions';
import { initialUndoRedoState, UndoRedoState } from './undo-redo.state';

const undoRedoReducer = createReducer<UndoRedoState>(
    initialUndoRedoState,
    on(undoDone, (state, _action): UndoRedoState => {
        const undo = [...state.undo];
        const redo = [...state.redo];

        const undone = undo.pop();
        if (undone) {
            redo.push(undone);
        }

        return {
            ...state,
            undo,
            redo,
        };
    }),
    on(redoDone, (state, _action): UndoRedoState => {
        const undo = [...state.undo];
        const redo = [...state.redo];

        const redone = redo.pop();
        if (redone) {
            undo.push(redone);
        }

        return {
            ...state,
            undo,
            redo,
        };
    }),
);

export function reducer(state: UndoRedoState | undefined, action: Action) {
    return undoRedoReducer(state, action);
}
