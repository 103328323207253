<appshell-main (clickOnComponent)="clickSolutionSwitcher($event)">
    @for (activeDialog of activeDialogs(); track activeDialog) {
        <priva-dynamic-component-slot data-dynamic-slot [dynamicComponent]="activeDialog" />
    }

    <priva-dynamic-component-slot
        data-dynamic-slot
        [dynamicComponent]="activePanel()"
        class="priva-dynamic-component"
    />
</appshell-main>

<div class="http-delay">
    @if (httpDelay()) {
        <div
            class="http-delay__button blink"
            priva-button
            iconClass="fas fa-triangle-exclamation "
            iconState="left"
            importance="quaternary"
            size="sm"
        >
            {{ 'APP.OVERVIEW.HTTP_DELAY' | translate }}
        </div>
    }
</div>
