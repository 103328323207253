<div
    id="root"
    cdkDropListGroup
    [class.has-notification-bar]="hasNotificationBar"
    [class.has-primary-nav]="hasPrimaryNav"
    [priva-toggle-class]="{ contentActions: 'has-content-overlay' }"
    [appshell-deactivate-area]="[AreaTypes.SidebarLocalLeft]"
    [priva-toggle-deactivate]="['contentActions', 'showPrimaryNavItems']"
    next-throbber
    [throbbers]="throbbers$ | async"
    app-undo-redo
    app-duplicate
>
    <!-- AREA | COMPONENT: Notification messages -->
    <priva-notifications />

    <!-- App Shell main component -->
    <router-outlet />

    <!-- Global error handling -->
    @if (error$ | async; as nextError) {
        <app-status-error-page
            class="app__error-page"
            data-test="app-error-page"
            [title]="nextError.title"
            [info]="nextError.subTitle"
            [actionTitle]="nextError.actionTitle"
            [actionHtmlId]="nextError.actionHtmlId"
            [actionHtmlClass]="nextError.actionHtmlClass"
            (actionClicked)="dispatchAction(nextError.action)"
        />
    }
</div>
