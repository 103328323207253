import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';
import { PrivaLocalizationModule } from '@priva/localization';

import { WhatsNewComponent } from './whats-new.component';

@NgModule({
    imports: [CommonModule, PrivaLocalizationModule, PrivaDialogSimpleModule],
    declarations: [WhatsNewComponent],
    exports: [WhatsNewComponent],
})
export class WhatsNewModule {}
