import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DuplicateDirective } from './duplicate.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [DuplicateDirective],
    exports: [DuplicateDirective],
})
export class DuplicateModule {}
