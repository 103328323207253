import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppActions, AppState } from 'app/state';

@Directive({
    selector: '[app-duplicate]',
})
export class DuplicateDirective {
    constructor(private store$: Store<{ app: AppState }>) {}

    @HostListener('window:keydown', ['$event'])
    public listen(keyEvent: KeyboardEvent) {
        if (keyEvent.code === 'KeyD' && (keyEvent.ctrlKey || keyEvent.metaKey) && !keyEvent.shiftKey) {
            this.store$.dispatch(AppActions.duplicatePressed());
            keyEvent.preventDefault();
        }
    }
}
